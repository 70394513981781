/* eslint-disable no-unused-vars,class-methods-use-this */

export default class Middleware {
  /**
   * @param {object} operation
   * @param {string} operation.operationName
   * @param {Function} operation.getContext
   * @returns {object};
   */
  getHeaders(operation) {
    return null;
  }

  /**
   * @param {object} operation
   * @param {string} operation.operationName
   * @param {Function} operation.getContext
   */
  beforeRequest(operation) {}

  /**
   * @param {object} operation
   * @param {string} operation.operationName
   * @param {Function} operation.getContext
   * @param {object} result
   */
  afterRequest(operation, result) {}
}
